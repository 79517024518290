import React from 'react';

import { connect } from 'react-redux';
import store from "@/src/store";

import FeedbackFastLink from '@/src/components/FeedbackFastLink';
import ReportCategoryDropdown from '@/src/components/ReportCategoryDropdown';

function mapAppStateToProps(appState) {
  return {
    triageTable: appState.triageTable,
  };
}

class TriageTable extends React.Component {
  loadMore = () => {
    const { triageTable } = this.props;
    const reports = triageTable.showReportIds.map((reportId) => {
      return triageTable.reportsById[reportId]
    });
    const lastReportId = reports[reports.length - 1].id;
    fetch(`${this.props.triageReportsEndpoint}.json?after_id=${lastReportId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    }).then((response) => {
      return response.json();
    }).then((reports) => {
      store.dispatch({
        type: 'FETCHED_REPORTS',
        afterId: lastReportId,
        reports
      });
    });
  };

  render() {
    const { triageTable } = this.props;

    return (
      <table className='TriageTable table reports'>
        <thead>
          <tr>
            <th></th>
            <th>Version</th>
            <th>OS</th>
            <th>ID</th>
            <th>Email</th>
            <th>Received</th>
            <th>Type</th>
            <th>Category</th>
            <th><i className='icon-file'></i></th>
          </tr>
        </thead>
        <tbody>
          {triageTable.showReportIds.map((reportId) => {
            return triageTable.reportsById[reportId];
          }).map((report) => (
            <TriageTableRow
              key={report.id}
              report={report}
              reportsEndpoint={this.props.reportsEndpoint}
              reportCategories={this.props.reportCategories}
            />
          ))}
          <tr>
            {!triageTable.fetchedAll ? (
              <td className="load-more" colSpan="8" onClick={this.loadMore}>
                Load more reports
              </td>
            ) : (
              <td className="cannot-load-more" colSpan="8">
                End of reports to triage
              </td>
            )}
          </tr>
        </tbody>
      </table>
    )
  }
}

class TriageTableRow extends React.Component {
  render() {
    const { report } = this.props;

    const className = [
      `${report.report_type}_report`,
      report.category != null && 'categorized'
    ].filter(Boolean).join(' ')

    return (
      <tr className={className}>
        <TriageTableCell className='report_icon' noReportLink>
          {report.project && (
            <a href={report.project.url}>
              {report.project.icon_url ? (
                <img src={report.project.icon_url} />
              ) : (
                <strong>
                  {report.project.name}
                </strong>
              )}
            </a>
          )}
        </TriageTableCell>
        <TriageTableCell noReportLink>
          {report.release ? (
            <a href={report.release.url}>
              {report.version}
            </a>
          ) : (
            "Unknown"
          )}
        </TriageTableCell>
        <TriageTableCell report={report}>
          {report.os_version}
        </TriageTableCell>
        <TriageTableCell noReportLink>
          <FeedbackFastLink report={report} />
        </TriageTableCell>
        <TriageTableCell noReportLink>
          {report.email ? (
            <a href={`${this.props.reportsEndpoint}?q="${report.email}"`}>
              {report.email}
            </a>
          ) : (
            "No email given"
          )}
        </TriageTableCell>
        <TriageTableCell report={report}>
          {report.pretty_created_at}
        </TriageTableCell>
        <TriageTableCell report={report}>
          {report.report_type}
        </TriageTableCell>
        <TriageTableCell noReportLink>
          <ReportCategoryDropdown
            report={report}
            categories={this.props.reportCategories}
          />
        </TriageTableCell>
        <TriageTableCell report={report} className='icons'>
          {report.attachments?.size > 0 && (
            <i
              className='icon-file'
              title={report.attachments.map((a) => a.filename).join(', ')}
            />
          )}
        </TriageTableCell>
      </tr>
    );
  }
}

const TriageTableCell = (props) => {
  const noLinkStyle = {
    padding: '8px'
  };

  const linkStyle = {
    textDecoration: 'none',
    display: 'block',
    padding: '8px',
    color: 'rgb(51, 51, 51)'
  };

  return props.noReportLink ? (
    <td className={props.className} style={noLinkStyle}>
      {props.children}
    </td>
  ) : (
    <td className={props.className}>
      <a
        href={props.report.url}
        style={linkStyle}
      >
        {props.children}
      </a>
    </td>
  );
};

export default connect(mapAppStateToProps)(TriageTable);
