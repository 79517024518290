export type DebouncerOptions = {
  delay?: number;
};

class Debouncer {
  delay: number;
  timeout: ReturnType<typeof setTimeout> | undefined;

  constructor(options: DebouncerOptions = {}) {
    const { delay = 500 } = options;

    this.delay = delay;
  }

  run(callback: () => void) {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(callback, this.delay);
  }

  cancel() {
    clearTimeout(this.timeout);
  }
}

export default Debouncer;
