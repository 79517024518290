import { Consumer } from '@rails/actioncable';
import consumer from './consumer';

type Args = {
  name: string;
};

class Channel {
  consumer: Consumer;
  name: string;

  constructor(args: Args) {
    this.consumer = consumer;
    this.name = args.name;
  }

  create(...args: Parameters<typeof this.consumer.subscriptions.create>) {
    return this.consumer.subscriptions.create(...args);
  }
}

export default Channel;
