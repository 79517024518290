import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/utils/cn';

const alertVariants = cva(
  'relative w-full rounded-lg border border-background-mid/50 p-4',
  {
    variants: {
      variant: {
        default: 'text-foreground bg-primary/5',
        destructive:
          'border-destructive/50 text-destructive dark:border-destructive [&>svg]:text-destructive',
        success:
          'border-success/50 text-success dark:border-success [&>svg]:text-success',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

type AlertProps = React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof alertVariants>;

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  ({ className, variant, ...props }, ref) => (
    <div
      ref={ref}
      role="alert"
      className={cn(alertVariants({ variant }), className)}
      {...props}
    />
  ),
);
Alert.displayName = 'Alert';

const AlertTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h5
    ref={ref}
    className={cn(
      'font-sans m-0 mb-1 font-medium leading-none tracking-tight',
      className,
    )}
    {...props}
  />
));
AlertTitle.displayName = 'AlertTitle';

const AlertDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('font-sans text-sm [&_p]:leading-relaxed', className)}
    {...props}
  />
));
AlertDescription.displayName = 'AlertDescription';

export type AlertWithIconProps = AlertProps & {
  title?: React.ReactNode;
  description?: React.ReactNode;
  icon?: React.ReactNode;
  action?: React.ReactNode;
} & VariantProps<typeof alertVariants>;

const AlertWithIcon = ({
  title,
  icon,
  description,
  action,
  ...rest
}: AlertWithIconProps) => {
  return (
    <Alert
      {...rest}
      className={cn('flex gap-3 justify-between', rest.className)}
    >
      <div className="flex gap-5">
        {icon}
        <div className="flex-1">
          {title && (
            <AlertTitle className={cn(!description && 'm-0')}>
              {title}
            </AlertTitle>
          )}
          {description && <AlertDescription>{description}</AlertDescription>}
        </div>
      </div>
      {action}
    </Alert>
  );
};

export { Alert, AlertTitle, AlertDescription, AlertWithIcon };
