import React, { type HTMLAttributes, type DetailedHTMLProps } from 'react';
import { cn } from '@/utils/cn';

type Props = DetailedHTMLProps<
  HTMLAttributes<HTMLParagraphElement>,
  HTMLParagraphElement
>;

const baseClasses = 'm-0 text-base text-foreground font-sans';

export const P = ({ className, ...props }: Props) => {
  return <p {...props} className={cn(baseClasses, className)} />;
};

export const A = ({
  className,
  ...props
}: DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>) => {
  return <a {...props} className={cn('text-primary underline', className)} />;
};

export const H1 = (
  props: DetailedHTMLProps<
    HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  >,
) => (
  <h1
    {...props}
    className={cn(
      baseClasses,
      'text-4xl font-serif tracking-wide',
      props.className,
    )}
  />
);

export const H2 = (
  props: DetailedHTMLProps<
    HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  >,
) => <h2 {...props} className={cn(baseClasses, 'text-3xl', props.className)} />;

export const H3 = (
  props: DetailedHTMLProps<
    HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  >,
) => <h3 {...props} className={cn(baseClasses, 'text-2xl', props.className)} />;

export const H4 = (
  props: DetailedHTMLProps<
    HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  >,
) => <h4 {...props} className={cn(baseClasses, 'text-lg', props.className)} />;

export const H5 = (
  props: DetailedHTMLProps<
    HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  >,
) => (
  <h5 {...props} className={cn(baseClasses, 'text-base', props.className)} />
);
