import * as React from 'react';
import * as AvatarPrimitive from '@radix-ui/react-avatar';

import { cn } from '@/utils/cn';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from './tooltip';

const Avatar = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Root
    ref={ref}
    className={cn(
      'relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full',
      className,
    )}
    {...props}
  />
));
Avatar.displayName = AvatarPrimitive.Root.displayName;

const AvatarImage = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Image>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Image
    ref={ref}
    className={cn('aspect-square h-full w-full', className)}
    {...props}
  />
));
AvatarImage.displayName = AvatarPrimitive.Image.displayName;

const AvatarFallback = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Fallback>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Fallback
    ref={ref}
    className={cn(
      'flex h-full w-full items-center justify-center rounded-full bg-foreground/30 font-bold text-white',
      className,
    )}
    {...props}
  />
));
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

const getAvatarColor = (name: string) => {
  const bgcolors = [
    'bg-blue-500',
    'bg-green-600',
    'bg-teal-600',
    'bg-sky-600',
    'bg-indigo-500',
    'bg-fuchsia-500',
    'bg-rose-500',
    'bg-orange-600',
  ];

  const charArray = Array.from(name);
  const hash = charArray.reduce((total, _char, index) => {
    return (total += name.charCodeAt(index) * index);
  }, 0);

  const userBg = hash % bgcolors.length;
  return bgcolors[userBg];
};

type AvatarUserProps = {
  userId: number;
  name: string;
};

const UserAvatar = ({
  userId,
  name,
  wrapperClassName,
  fallbackProps,
}: AvatarUserProps & {
  wrapperClassName?: string;
  fallbackProps?: AvatarPrimitive.AvatarFallbackProps;
}) => {
  return (
    <Avatar className={cn(wrapperClassName)}>
      <AvatarImage
        src={`/settings/avatar_for_user?thumb=32&user_id=${userId}`}
        alt={name}
      />
      <AvatarFallback
        {...fallbackProps}
        className={cn(getAvatarColor(name), fallbackProps?.className)}
      >
        {name.slice(0, 2)}
      </AvatarFallback>
    </Avatar>
  );
};

const UserAvatarGroup = ({
  users,
  avatarClassName,
}: {
  users: AvatarUserProps[];
  avatarClassName?: string;
}) => {
  return (
    <TooltipProvider>
      <div className="flex">
        {users.map((user, i) => (
          <div
            key={user.userId}
            className={`hover:-translate-y-1 hover:z-10 transition-all relative ${i > 0 ? 'ml-[-1rem]' : ''}`}
          >
            <Tooltip>
              <TooltipTrigger>
                <UserAvatar {...user} wrapperClassName={avatarClassName} />
              </TooltipTrigger>
              <TooltipContent>{user.name}</TooltipContent>
            </Tooltip>
          </div>
        ))}
      </div>
    </TooltipProvider>
  );
};

export { Avatar, AvatarImage, AvatarFallback, UserAvatar, UserAvatarGroup };
