import React, { useEffect, useRef, useState } from 'react';
import { Popover, PopoverContent } from '@/components/ui/popover';
import useDialog from '@/hooks/useDialog';
import { Button } from '@/components/ui/button';
import { Copy, CopyPlus } from 'lucide-react';
import { PopoverAnchor } from '@radix-ui/react-popover';
import { toastError, toastSuccess } from '@/utils/toast';

type Props = {
  baseText: string;
};

const iconClasses = 'w-4 h-4 mr-2 opacity-50';
const buttonProps = {
  className: 'w-full flex justify-start font-normal',
  variant: 'ghost' as const,
};

const ReportPreSelector = ({ baseText }: Props) => {
  const popover = useDialog();
  const [rect, setRect] = useState<{
    left: number;
    top: number;
    width: number;
    height: number;
  } | null>(null);
  const [text, setText] = useState<string | null>(null);
  const currentSelectionTextRef = useRef<string | null>(null);

  const getCurrentSelection = () => {
    let text = '';
    let rect;

    const selection = document.getSelection();
    if (!selection) return;

    // Make sure there's range
    if (selection?.rangeCount && selection.rangeCount > 0) {
      const newSelectedText = selection.toString();

      const range = selection.getRangeAt(0);
      const element = range.commonAncestorContainer;

      // Only run if we're highlighting within a pre tag
      if (
        element.nodeType === Node.TEXT_NODE &&
        element.parentElement?.nodeName === 'PRE' &&
        newSelectedText.length > 0
      ) {
        rect = range.getBoundingClientRect();
        rect = {
          left: rect.x,
          top: rect.y + window.scrollY,
          width: rect.width,
          height: rect.height,
        };

        text = newSelectedText;
      }
    }

    return text && rect ? { text, rect } : undefined;
  };

  const handleSelection = () => {
    // Make sure there's range
    const result = getCurrentSelection();

    if (result) {
      popover.show();
      currentSelectionTextRef.current = result.text;
      setRect(result.rect);
    }
  };

  const handleClick = () => {
    const newText =
      baseText +
      (currentSelectionTextRef.current
        ? `\n\n\`\`\`\n${currentSelectionTextRef.current}\n\`\`\``
        : '');

    // Set current text
    setText(newText);

    navigator.clipboard.writeText(newText);

    toastSuccess('Copied to clipboard');
  };

  const handleAppendToClipboard = async () => {
    const newSelection = getCurrentSelection();

    if (newSelection) {
      let newText = text || '';

      if (newSelection.text) {
        newText += `\n\n\`\`\`\n${newSelection.text}\n\`\`\``;
      }

      setText(newText);

      navigator.clipboard.writeText(newText);

      toastSuccess('Appended to clipboard');
    }
  };

  useEffect(() => {
    document.addEventListener('pointerup', handleSelection);

    return () => {
      document.removeEventListener('pointerup', handleSelection);
    };
  }, []);

  return (
    <Popover
      {...popover}
      onOpenChange={(open) => {
        if (!open) {
          currentSelectionTextRef.current = null;
          setRect(null);
        }

        popover.onOpenChange(open);
      }}
    >
      <PopoverAnchor
        className="absolute pointer-events-none"
        style={{ ...rect }}
      />
      {rect && (
        <PopoverContent
          className="p-1"
          onOpenAutoFocus={(e) => {
            e.preventDefault();
          }}
        >
          <Button
            {...buttonProps}
            onClick={() => {
              popover.hide();

              setTimeout(() => {
                handleClick();
                // Delay until after the popover is closed so we don't render the option before the popover
                // is closed
              }, 250);
            }}
          >
            <Copy className={iconClasses} />
            <div>Copy with Report Info</div>
          </Button>
          {!!text && (
            <Button
              {...buttonProps}
              onClick={() => {
                handleAppendToClipboard();

                popover.hide();
              }}
            >
              <CopyPlus className={iconClasses} />
              <div>Append to Clipboard</div>
            </Button>
          )}
        </PopoverContent>
      )}
    </Popover>
  );
};

export default ReportPreSelector;
