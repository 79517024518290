import React from 'react';

export default class AddReportLinksForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      links: props.initializeLinksTo || [],
      report_id: props.report_id,
      new_link_url: '',
    };
  }

  render() {
    return (
      <div className='report-links'>
        <form onSubmit={this.saveLink}>
          <div className="input-prepend input-append">
            <span className="add-on"><i className='icon-th-list'></i></span>
            <input
              autoComplete='off'
              type="text"
              className="link-input"
              placeholder={this.props.placeholder}
              value={this.state.new_link_url}
              onChange={this.updateNewLinkURL}
              list={this.props.showCommonIssues ? "commonIssues" : undefined}
            />
            {this.props.showCommonIssues && (
                <datalist id="commonIssues">
                  {SHOW_REPORT.commonGithubIssues.map((issue) => (
                    <option key={issue[1]} value={issue[1]}>{issue[0]}</option>
                  ))}
                </datalist>
            )}
            <input type='submit' className='btn h-[30px]' onClick={this.saveLink} value='Add' />
          </div>
        </form>
        <div>
          {this.state.links.map((link) => (
            <Link
              key={link.id}
              link={link}
              endpoint={this.props.endpoint}
              onRemove={this.hideLink}
            />
          ))}
        </div>
      </div>
    );
  }

  updateNewLinkURL = (event) => {
    this.setState({new_link_url: event.target.value});
  };

  saveLink = (event) => {
    event.preventDefault();

    f53.log('adding link to report', this.props.report_id);
    $.ajax({
      url: this.props.endpoint,
      data: withAuthToken({
        [this.props.type]: {
          url: this.state.new_link_url,
          report_id: this.props.report_id,
        }
      }),
      type: 'post',
      datatype: 'json',
      success: (response) => {
        const oldLinks = this.state.links;
        this.setState({
          links: oldLinks.concat([response]),
          new_link_url: '',
        });
      },
      error: (response) => {
        let errors;
        try {
          errors = JSON.parse(response.responseText).errors;
        } catch(err) {
          errors = ['something went wrong'];
        }
        f53.log('did not add link to report, due to following errors:');
        errors.forEach(function(err) {
          f53.log('\t- ', err)
        });
        alert(`Could not save link: ${errors.join('\n')}`);
        this.setState({new_link_url: ''});
      },
    });
  };

  hideLink = (linkIdToHide) => {
    console.log('hide link called');
    const updatedLinks = this.state.links.filter((link) => {
      return link.id != linkIdToHide;
    });
    this.setState({links: updatedLinks});
  };
};

class Link extends React.Component {
  render() {
    const url = this.props.link.url;
    return (
      <div className='report-link'>
        <a href={url} target="_blank">{url}</a>
        <span className='remove' onClick={this.remove}>
          <i className='icon-remove'></i>
        </span>
      </div>
    );
  }

  remove = () => {
    if (!confirm(`Are you sure you want to remove this link?\n${this.props.link.url}`)) {
      return;
    }
    $.ajax({
      url: `${this.props.endpoint}/${this.props.link.id}`,
      data: withAuthToken({}),
      type: 'delete',
      datatype: 'json',
      success: (response) => {
        this.props.onRemove(this.props.link.id);
      },
      error: () => {
        f53.log('could not remove link from report');
      }
    });
  };
}
