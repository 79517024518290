import Debouncer from '@/utils/Debouncer';
import { UserProps } from '@/types/user';
import Channel from './Channel';

type CreateProps = {
  user: UserProps;
  onReceive?: (payload: AppearanceActionPayload) => void;
};

export enum AppearanceType {
  // User has come online via opening the window or refreshing,
  // this will trigger a "response" from the other online users.
  Appear = 'appear',
  // User is active, they've interacted with the page recently
  Active = 'active',
  // User is idle, they've gone to a different window but left the page open
  Idle = 'idle',
  // User is away, they've closed the window
  Away = 'away',
}

export type AppearanceActionPayload = {
  user_id: number;
  display_name: string;
  type: AppearanceType;
};

const name = 'AppearanceChannel';

const getPageKey = () => window.location.pathname.replace(/\//g, '-');

const FIVE_SECONDS = 5000;

/**
 * Controller for showing and hiding users on the page
 */
export class AppearanceChannel extends Channel {
  user: UserProps;

  channel: ReturnType<Channel['create']>;

  isIdle: boolean;
  isClosing: boolean;

  keydownDebouncer: Debouncer;

  constructor(props: CreateProps) {
    super({ name });
    const { user, onReceive } = props;
    this.user = user;

    // Track if the user has closed the page (make sure blur doesn't get called when closing the window)
    this.isClosing = false;

    // Track idle state so we can send user's state in a response
    this.isIdle = false;

    this.keydownDebouncer = new Debouncer({ delay: FIVE_SECONDS });

    const page = getPageKey();

    // Create subscription
    this.channel = this.create(
      {
        channel: this.name,
        page,
      },
      {
        connected: () => {
          this.install?.();

          // Initialize appearance
          this.sendAppear();
        },

        disconnected: () => {
          this.uninstall?.();
        },

        rejected: () => {
          this.uninstall?.();
        },

        received: (payload: AppearanceActionPayload) => {
          if (this.user.id === payload.user_id) return;

          onReceive?.(payload);

          /**
           * 👋 Wave back
           * Respond back to the "appear" to indicate to them that
           * we're also online.
           */
          if (payload.type === AppearanceType.Appear) {
            this.sendRespond();
          }
        },
      },
    );
  }

  private get payload() {
    return {
      user_id: this.user.id,
      display_name: this.user.name || this.user.email,
    };
  }

  sendRespond() {
    this.channel.send({
      type: this.isIdle ? AppearanceType.Idle : AppearanceType.Active,
      ...this.payload,
    });
  }

  sendAppear() {
    this.channel.send({
      type: AppearanceType.Appear,
      ...this.payload,
    });
  }

  sendAway() {
    this.isClosing = true;

    this.channel.send({
      type: AppearanceType.Away,
      ...this.payload,
    });
  }

  sendActive() {
    this.channel.send({
      type: AppearanceType.Active,
      ...this.payload,
    });
  }

  sendIdle() {
    this.channel.send({
      type: AppearanceType.Idle,
      ...this.payload,
    });
  }

  focus() {
    this.isIdle = false;
    this.sendActive();
  }

  click() {
    this.isIdle = false;
    this.sendActive();
  }

  keydown() {
    this.isIdle = false;
    this.keydownDebouncer.run(() => {
      this.sendActive();
    });
  }

  blur() {
    this.isIdle = true;

    // When user closes the window, blur seems to also get called sometimes,
    // leading to a race condition where `sendIdle` is sent after `sendAway` and the user
    // reappears. To prevent this, we set a flag to track if the user is closing the window.
    if (!this.isClosing) {
      this.sendIdle();
    }
  }

  install() {
    window.addEventListener('focus', this.focus.bind(this));
    window.addEventListener('blur', this.blur.bind(this));
    document.body.addEventListener('click', this.click.bind(this));
    document.body.addEventListener('keydown', this.keydown.bind(this));

    window.addEventListener('beforeunload', this.sendAway.bind(this));
  }

  uninstall() {
    window.removeEventListener('focus', this.focus);
    window.removeEventListener('blur', this.blur);
    document.body.removeEventListener('click', this.click);
    document.body.removeEventListener('keydown', this.keydown);

    window.removeEventListener('beforeunload', this.sendAway);
  }
}
