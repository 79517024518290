import React, { useMemo } from 'react';
import ReportNotesForm from '@/src/components/ReportNotesForm';
import { toastSuccess } from '@/utils/toast';
import { Button } from '@/components/ui/button';
import { Copy } from 'lucide-react';

import ReportPreSelector from './report-pre-selector';

type Props = {
  report_info: string;
  comment?: string;
  console_log_path?: string;
  raw_report_path?: string;
};

const TitleCell = (
  props: React.DetailedHTMLProps<
    React.TdHTMLAttributes<HTMLTableDataCellElement>,
    HTMLTableDataCellElement
  >,
) => <td className="font-bold w-32" {...props} />;

export const ReportsTable = (props: Props) => {
  const { report_info, comment } = props;

  const baseText = useMemo(() => {
    let info = report_info;

    if (comment?.trim().length > 0) {
      const lines = comment!.trim().split(/\r?\n/);
      info += `\n\n> ${lines.join("\n> ")}`;
    }

    return info;
  }, []);

  const handleCopy = () => {
    navigator.clipboard.writeText(baseText);
    toastSuccess('Copied to clipboard');
  };

  return (
    <div>
      <ReportPreSelector baseText={baseText} />

      <table className="table" style={{ marginBottom: 0 }}>
        <tbody>
          <tr>
            <TitleCell>Staff Notes</TitleCell>
            <td>
              <ReportNotesForm
                endpoint={window.SHOW_REPORT.updateReportEndpoint}
                report={window.SHOW_REPORT.report}
              />
            </td>
          </tr>
          <tr>
            <TitleCell>Report Info</TitleCell>
            <td>
              <div
                onClick={() => handleCopy()}
                className="flex gap-2 items-center"
              >
                <a className="cursor-pointer">{report_info}</a>
                <Button size="icon" variant="ghost" className="w-7 h-7">
                  <Copy className="w-3 h-3" />
                </Button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
