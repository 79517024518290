import React from 'react';
import { AlertExclamation } from '@/components/ui/alerts';
import { P } from '@/components/ui/typography';

export const Fallback = () => {
  return (
    <AlertExclamation
      title="Component Error"
      className="bg-destructive/10"
      description={
        <div className="flex flex-col gap-2">
          <P>
            Something&apos;s gone wrong with this UI. The following error
            reports have been logged, please screenshot this list and notify the
            development team. Sorry for the inconvenience!
          </P>
        </div>
      }
      variant="destructive"
    />
  );
};
