import React, { MouseEvent } from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { motion } from 'framer-motion';

import { cn } from '@/utils/cn';
import { LoaderCircle } from 'lucide-react';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-base font-bold font-sans ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground hover:bg-primary-hover',
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline:
          'border text-foreground border-foreground/20 bg-transparent hover:bg-foreground/5 hover:text-foreground',
        secondary:
          'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'text-foreground hover:bg-foreground/5 hover:text-foreground',
        link: 'text-primary underline-offset-4 hover:underline',

        // Used to match legacy items for now
        ['legacy-outline']:
          'border text-[#838383] border-[#C9C8C8] bg-[#fbfaf9] font-[DIN_Next_W01] hover:text-foreground rounded-[4px] font-sans',
      },
      size: {
        default: 'h-9 px-4 py-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);

Button.displayName = 'Button';

const LoadingButton = (props: ButtonProps) => {
  const { children, onClick, ...rest } = props;
  const [isLoading, setIsLoading] = React.useState(false);

  const handleClick = async (e: MouseEvent<HTMLButtonElement>) => {
    setIsLoading(true);
    await onClick?.(e);
    setIsLoading(false);
  };

  return (
    <Button
      {...rest}
      onClick={handleClick}
      disabled={isLoading || rest.disabled}
    >
      <div className={cn(isLoading && 'opacity-0')}>{children}</div>
      {isLoading && (
        <motion.div
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0, scale: 0 },
            visible: { opacity: 1, scale: 1 },
          }}
          className={cn(
            'absolute w-full h-full flex justify-center items-center',
          )}
        >
          <LoaderCircle className="animate-spin" />
        </motion.div>
      )}
    </Button>
  );
};

export { Button, LoadingButton, buttonVariants };
