import React from 'react';

import channelConsumer from '@/channels/consumer';
import withCSRFToken from '@/utils/withCSRFToken';
import store from '@/src/store';

import { NotifyUser } from '@/src/store';

export default class ReportNotesForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      staff_notes: props.report.staff_notes || '',
      report_id: props.report.id,
      saving: false,
    };

    channelConsumer.subscriptions.create(
      {
        channel: 'ReportsChannel',
      },
      {
        received: (data) => {
          const { report, newReport } = data;
          if (report.id === this.state.report_id) {
            this.setState({ staff_notes: report.staff_notes || '' });
          }
        },
      },
    );
  }

  render() {
    return (
      <div className="report-staff-notes">
        <textarea
          cols="80"
          rows="1"
          disabled={this.state.saving}
          onChange={this.updateSaveButton.bind(this)}
          value={this.state.staff_notes}
        ></textarea>
        <button
          className={this.state.staff_notes_changed ? 'btn-primary btn' : 'btn'}
          disabled={!this.state.staff_notes_changed}
          onClick={this.saveStaffNotes.bind(this)}
        >
          Save Notes
        </button>
      </div>
    );
  }

  updateSaveButton(event) {
    const newStaffNotes = event.target.value;
    var oldStaffNotes = this.state.original_staff_notes;

    if (!oldStaffNotes) {
      oldStaffNotes = this.state.staff_notes;
      this.setState({
        original_staff_notes: oldStaffNotes,
      });
    }
    this.setState({
      staff_notes_changed: newStaffNotes != oldStaffNotes,
      staff_notes: newStaffNotes,
    });
  }

  saveStaffNotes(event) {
    this.setState({
      saving: true,
    });
    const newStaffNotes = this.state.staff_notes;

    fetch(`${this.props.endpoint}.json`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(
        withCSRFToken({
          report: Object.assign({}, this.props.report, {
            staff_notes: newStaffNotes,
          }),
        }),
      ),
    })
      .then((response) => {
        return response.json();
      })
      .then((report) => {
        store.dispatch({
          type: 'UPDATE_REPORTS',
          report,
        });
        this.setState({ saving: false, staff_notes_changed: false });
        NotifyUser({
          message: 'Staff notes updated',
          timeoutMs: 2000,
        });
      })
      .catch((error) => {
        this.setState({ saving: false });
        alert(error);
      });
  }
}
