import ReactDOM from 'react-dom';
import React from 'react';
import { Provider } from 'react-redux';

import channelConsumer from '@/channels/consumer';
import store from '@/src/store';
import TriageTable from '@/src/components/TriageTable';

document.addEventListener('DOMContentLoaded', () => {
  if (!window.TRIAGE_PAGE) return;

  store.dispatch({
    type: 'INIT_TRIAGE_TABLE',
    reports: window.TRIAGE_PAGE.initialReports,
  });

  channelConsumer.subscriptions.create(
    {
      channel: 'ReportsChannel',
    },
    {
      received: (data) => {
        const { report, newReport } = data;
        if (report) {
          store.dispatch({
            type: 'UPDATE_REPORTS',
            report: report,
          });
        }

        if (newReport) {
          store.dispatch({
            type: 'NEW_REPORT',
            report: newReport,
          });
        }
      },
    },
  );

  const container = document.getElementById('triage-table');
  ReactDOM.createRoot(container).render(
    <Provider store={store}>
      <TriageTable
        reportCategories={window.TRIAGE_PAGE.reportCategories}
        reportsEndpoint={window.TRIAGE_PAGE.reportsEndpoint}
        triageReportsEndpoint={window.TRIAGE_PAGE.triageReportsEndpoint}
      />
    </Provider>,
  );
});
