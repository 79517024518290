import React from 'react';

import withCSRFToken from '@/utils/withCSRFToken';
import store from '@/src/store';

export default class ReportCategoryDropdown extends React.Component {
  state = {
    saving: false,
  };

  categorizeReport = (event) => {
    this.setState({ saving: true });
    fetch(`${this.props.report.url}.json`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(
        withCSRFToken({
          report: Object.assign({}, this.props.report, {
            category: event.target.value,
          }),
        }),
      ),
    })
      .then((response) => {
        return response.json();
      })
      .then((report) => {
        store.dispatch({
          type: 'UPDATE_REPORTS',
          report,
        });
        this.setState({ saving: false });
      })
      .catch(() => {
        this.setState({ saving: false });
      });
  };

  render() {
    return (
      <select
        value={this.props.report.category || ''}
        disabled={this.state.saving}
        onChange={this.categorizeReport}
      >
        <option value="">Uncategorized</option>
        {Object.keys(this.props.categories).map((key) => (
          <option key={key} value={key}>
            {this.props.categories[key]}
          </option>
        ))}
      </select>
    );
  }
}
