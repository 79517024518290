import { useState } from 'react';

export type UseDialogType<T = undefined> = {
  open: boolean;
  setOpen: (open: boolean) => void;
  toggle: () => void;
  show: (newState?: T) => void;
  hide: (newState?: T) => void;
  state?: T;
  setState: (state: T) => void;
  onOpenChange: (open: boolean) => void;
};

export default function useDialog<T = undefined>() {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState<T>();

  const toggle = () => {
    setOpen(!open);
  };

  const show = (newState?: T) => {
    if (newState) setState(newState);

    setOpen(true);
  };

  const hide = (newState?: T) => {
    if (newState) setState(newState);

    setOpen(false);
  };

  return {
    open,
    setOpen,
    state,
    setState,
    toggle,
    show,
    hide,
    onOpenChange: setOpen,
  };
}
