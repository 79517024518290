import ReactDOM from 'react-dom/client';
import React from 'react';

import CategorizeReportForm from '@/src/components/CategorizeReportForm';
import ReportTypeForm from '@/src/components/ReportTypeForm';
import AddReportLinksForm from '@/src/components/AddReportLinksForm';
import ReportRelease from '@/src/components/ReportRelease';
import ReportNotesForm from '@/src/components/ReportNotesForm';

document.addEventListener('DOMContentLoaded', () => {
  if (!window.SHOW_REPORT) return;

  ReactDOM.createRoot(
    document.getElementById('mount-categorize-report-form'),
  ).render(
    <CategorizeReportForm
      endpoint={window.SHOW_REPORT.categorizeReportEndpoint}
      categories={window.SHOW_REPORT.categories}
      report={window.SHOW_REPORT.report}
    />,
  );

  ReactDOM.createRoot(document.getElementById('mount-report-type-form')).render(
    <ReportTypeForm
      endpoint={window.SHOW_REPORT.updateReportEndpoint}
      report_types={window.SHOW_REPORT.report_types}
      report={window.SHOW_REPORT.report}
    />,
  );

  ReactDOM.createRoot(
    document.getElementById('mount-add-github-issues-form'),
  ).render(
    <AddReportLinksForm
      endpoint={window.SHOW_REPORT.addGithubIssuesEndpoint}
      report_id={window.SHOW_REPORT.report.id}
      initializeLinksTo={window.SHOW_REPORT.reportGithubIssues}
      placeholder="add a github issue url"
      type="github_issue"
      showCommonIssues={true}
    />,
  );

  ReactDOM.createRoot(
    document.getElementById('mount-add-redphone-tickets-form'),
  ).render(
    <AddReportLinksForm
      endpoint={window.SHOW_REPORT.addRedphoneTicketsEndpoint}
      report_id={window.SHOW_REPORT.report.id}
      initializeLinksTo={window.SHOW_REPORT.reportRedphoneTickets}
      placeholder="add a redphone ticket url"
      type="redphone_ticket"
      showCommonIssues={false}
    />,
  );

  ReactDOM.createRoot(document.getElementById('mount-report-release')).render(
    <ReportRelease
      report={window.SHOW_REPORT.report}
      crashLog={window.SHOW_REPORT.crashLog}
      crashLogPath={window.SHOW_REPORT.crashLogPath}
      release={window.SHOW_REPORT.release}
      releasePath={window.SHOW_REPORT.releasePath}
      submissionRelease={window.SHOW_REPORT.submissionRelease}
      submissionReleasePath={window.SHOW_REPORT.submissionReleasePath}
    />,
  );
});
