import React, { useEffect, useState } from 'react';
import {
  AppearanceActionPayload,
  AppearanceChannel,
  AppearanceType,
} from '@/channels/AppearanceChannel';
import { UserAvatar } from '@/components/ui/user-avatar';
import { UserProps } from '@/types/user';
import { AnimatePresence, motion } from 'framer-motion';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { cn } from '@/utils/cn';

type Props = {
  user: UserProps;
};

const ActivePip = ({ className }: { className?: string }) => (
  <span
    className={cn(
      `transition-all rounded-full w-[15px] h-[15px] absolute bottom-[-3px] right-[-3px] border-2 border-slate-300`,
      className,
    )}
  />
);

const FORTY_FIVE_MINUTES = 1000 * 60 * 45;
const TWO_MINUTES = 1000 * 60 * 2;

type UserAppearanceProps = {
  id: number;
  displayName: string;
  state: AppearanceType;
  lastSeenAt: number;
};

export const AppearanceManager = (props: Props) => {
  const { user } = props;

  const [users, setUsers] = useState<UserAppearanceProps[]>([]);
  const [, checkUserLastSeenAt] = useState(Date.now());

  const updateUsers = (payload: AppearanceActionPayload) => {
    // User has appeared
    setUsers((prevUsers) => {
      const newUsers = [...prevUsers];

      const newUser = {
        id: payload.user_id,
        displayName: payload.display_name,
        state: payload.type,
        lastSeenAt: Date.now(),
      };

      const idx = newUsers.findIndex((u) => u.id === payload.user_id);
      if (idx > -1) {
        newUsers.splice(idx, 1, newUser);
      } else {
        newUsers.push(newUser);
      }

      return newUsers;
    });
  };

  useEffect(() => {
    new AppearanceChannel({
      user,
      onReceive(payload) {
        updateUsers(payload);
      },
    });
  }, []);

  // Force a re-render every 2 minutes to potentially filter out stale users
  useEffect(() => {
    const interval = setInterval(() => {
      checkUserLastSeenAt(Date.now());
    }, TWO_MINUTES);

    return () => clearInterval(interval);
  }, []);

  // Filter out stale users
  const visibleUsers = users.filter(
    (u) =>
      u.lastSeenAt > Date.now() - FORTY_FIVE_MINUTES &&
      u.state !== AppearanceType.Away,
  );

  return (
    <TooltipProvider>
      <AnimatePresence>
        {visibleUsers.length && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="flex flex-col gap-2"
          >
            <div className="flex flex-wrap gap-2 item-center justify-end h-full">
              {visibleUsers
                .filter((u) => u.id !== user.id)
                .map((u) => {
                  const isIdle = u.state === AppearanceType.Idle;

                  return (
                    <motion.div
                      layout
                      initial={{ opacity: 0, scale: 0.5 }}
                      animate={{ opacity: 1, scale: 1 }}
                      exit={{ opacity: 0, scale: 0.5 }}
                      key={u.id}
                    >
                      <Tooltip>
                        <TooltipTrigger className="flex flex-col gap-1 items-center w-[50px]">
                          <div className="relative">
                            <UserAvatar
                              userId={u.id}
                              name={u.displayName}
                              className="w-8 h-8"
                            />
                            <ActivePip
                              className={
                                isIdle ? 'bg-slate-400' : 'bg-green-600'
                              }
                            />
                          </div>
                        </TooltipTrigger>

                        <TooltipContent className="flex flex-col gap-1">
                          <div className="font-bold">{u.displayName}</div>
                          <div className="flex gap-2">
                            <ActivePip
                              className={`${isIdle ? 'bg-slate-400' : 'bg-green-600'} relative block left-0`}
                            />
                            <div className="flex-1 opacity-90">
                              {isIdle
                                ? 'Viewed this page recently'
                                : 'Is viewing this page now'}
                            </div>
                          </div>
                        </TooltipContent>
                      </Tooltip>
                    </motion.div>
                  );
                })}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </TooltipProvider>
  );
};
