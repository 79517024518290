import React from 'react';

import channelConsumer from '@/channels/consumer';

import { NotifyUser } from '@/src/store';

export default class CategorizeReportForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category: props.report.category || '',
      report_id: props.report.id,
      saving: false,
    };

    channelConsumer.subscriptions.create(
      {
        channel: 'ReportsChannel',
      },
      {
        received: (data) => {
          const { report, newReport } = data;
          if (report.id === this.state.report_id) {
            this.setState({ category: report.category || '' });
          }
        },
      },
    );
  }

  render() {
    return (
      <div className="report-category">
        <form>
          <select
            disabled={this.state.saving}
            onChange={this.saveCategory.bind(this)}
            value={this.state.category}
          >
            <option value="">Uncategorized</option>
            {this.renderCategories.call(this)}
          </select>
        </form>
      </div>
    );
  }

  renderCategories() {
    return Object.keys(this.props.categories).map((key) => {
      return (
        <option key={key} value={key}>
          {this.props.categories[key]}
        </option>
      );
    });
  }

  saveCategory(event) {
    const newCategory = event.target.value;
    const oldCategory = this.state.category;
    this.setState({
      saving: true,
      category: newCategory,
    });
    $.ajax({
      url: this.props.endpoint,
      data: withAuthToken({
        report: {
          category: event.target.value,
        },
      }),
      type: 'put',
      datatype: 'json',
      success: (response) => {
        this.setState({ saving: false });
        NotifyUser({
          message: 'Report category updated',
          timeoutMs: 2000,
        });
      },
      error: (response) => {
        this.setState({
          saving: false,
          category: oldCategory,
        });
        alert(`Could not update category`);
      },
    });
  }
}
