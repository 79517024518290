import React from 'react';
import { AlertWithIcon, AlertWithIconProps } from '@/components/ui/alert';
import { CircleAlert, Info } from 'lucide-react';

type Props = Omit<AlertWithIconProps, 'icon'>;

export const AlertExclamation = (props: Props) => {
  return (
    <AlertWithIcon
      icon={<CircleAlert className="h-4 w-4" />}
      {...props}
    ></AlertWithIcon>
  );
};

export const AlertInfo = (props: Props) => {
  return (
    <AlertWithIcon
      icon={<Info className="h-4 w-4" />}
      {...props}
    ></AlertWithIcon>
  );
};
