import ReactDOM from 'react-dom/client';
import React from 'react';
import { Provider } from 'react-redux';

import store from '@/src/store';
import Notifications from '@/src/components/Notifications';

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('notifications');
  const root = ReactDOM.createRoot(container);
  root.render(
    <Provider store={store}>
      <Notifications />
    </Provider>,
  );
});
