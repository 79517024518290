import React from 'react';

import channelConsumer from '@/channels/consumer';

import { NotifyUser } from '@/src/store';

export default class ReportTypeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      report_type: props.report.report_type || '',
      report_id: props.report.id,
      saving: false,
    };

    channelConsumer.subscriptions.create(
      {
        channel: 'ReportsChannel',
      },
      {
        received: (data) => {
          const { report, newReport } = data;
          if (report.id === this.state.report_id) {
            this.setState({ category: report.report_type || '' });
          }
        },
      },
    );
  }

  render() {
    return (
      <div className="report-report-type">
        <select
          disabled={this.state.saving}
          onChange={this.saveReportType.bind(this)}
          value={this.state.report_type}
        >
          {this.renderReportTypes.call(this)}
        </select>
      </div>
    );
  }

  renderReportTypes() {
    return Object.keys(this.props.report_types).map((key) => {
      return (
        <option key={key} value={key}>
          {this.props.report_types[key]}
        </option>
      );
    });
  }

  saveReportType(event) {
    const newReportType = event.target.value;
    const oldReportType = this.state.report_type;
    this.setState({
      saving: true,
      report_type: newReportType,
    });
    $.ajax({
      url: this.props.endpoint,
      data: withAuthToken({
        report: { report_type: event.target.value },
      }),
      type: 'put',
      datatype: 'json',
      success: (response) => {
        this.setState({ saving: false });
        NotifyUser({
          message: 'Report type updated',
          timeoutMs: 2000,
        });
      },
      error: (response) => {
        this.setState({
          saving: false,
          report_type: oldReportType,
        });
        alert(`Could not update report type`);
      },
    });
  }
}
