import Honeybadger from '@honeybadger-io/js';

Honeybadger.configure({
  apiKey: window.HONEYBADGER_FRONTEND_TOKEN,
  environment: window.HONEYBADGER_ENV,
});

Honeybadger.setContext({
  user_id: window.CURRENT_USER_ID,
});
