import React from 'react';
import ReactDOM from 'react-dom/client';
import * as components from '@/components/mountables';
import { ErrorBoundary } from 'react-error-boundary';
import { Fallback } from '@/components/fallback';
import { REACT_RELOAD_TARGET_EVENT } from '@/utils/constants';
import _ from 'lodash';

const REACT_COMPONENT_ATTRIBUTE = 'data-react-component';
const REACT_LOADED_ATTRIBUTE = 'data-react-loaded';

const mount = (mountPoint: HTMLElement) => {
  const { dataset } = mountPoint as HTMLElement;
  const componentName = dataset.reactComponent;
  if (componentName) {
    // NB: not sure how to type-check the map that results from `import * as ...`
    // @ts-ignore
    const Component = components[componentName];
    if (Component) {
      let props = {};
      try {
        props = JSON.parse(dataset.props || '{}');
      } catch (e) {
        console.error('Error parsing props:', e);
      }

      const root = ReactDOM.createRoot(mountPoint);

      root.render(
        <ErrorBoundary FallbackComponent={Fallback}>
          <Component {...props} />
        </ErrorBoundary>,
      );

      mountPoint.setAttribute(REACT_LOADED_ATTRIBUTE, 'true');

      return root;
    } else {
      console.warn(
        'WARNING: No component found for: ',
        dataset.reactComponent,
        components,
      );
    }
  }
};

let rootItems: {
  root: ReactDOM.Root | undefined;
  element: HTMLElement;
}[] = [];

const load = (target: HTMLElement, options: { ignore?: string[] } = {}) => {
  const { ignore = [] } = options;

  // Partition the child elements of the target and the rest of the items
  const [children, rest] = _.partition(rootItems, (item) =>
    target.contains(item.element),
  );

  children
    // Ignore any elements in the ignore list
    .filter(
      (item) =>
        !ignore.includes(
          item.element?.getAttribute(REACT_COMPONENT_ATTRIBUTE) || '',
        ),
    )
    // Unmount the rest
    .forEach((item) => {
      item.root?.unmount();
    });

  // Get all the mount points
  const mountPoints = document.querySelectorAll(
    `[${REACT_COMPONENT_ATTRIBUTE}]`,
  );

  rootItems = [
    ...rest,
    ...Array.from(mountPoints)
      // Filter out any mount points that have already been loaded
      .filter((mountPoint) => !mountPoint.getAttribute(REACT_LOADED_ATTRIBUTE))
      // Only add the mount points that are children of the target
      .filter((mountPoint) => target.contains(mountPoint))
      // Mount the remaining mount points
      .map((mountPoint) => ({
        root: mount(mountPoint as HTMLElement),
        element: mountPoint as HTMLElement,
      })),
  ];
};

document.addEventListener('DOMContentLoaded', () => load(document.body));

/**
 * Listen for the react reload event and reload anything in the passed target element
 */
/** @ts-ignore */
document.addEventListener(REACT_RELOAD_TARGET_EVENT, (e: CustomEvent) => {
  const { ignore = [], target } = e.detail;
  console.log('REACT_RELOAD_TARGET_EVENT', e.detail);
  if (target) {
    load(e.detail.target, { ignore });
  }
});
