import React from 'react';

import withCSRFToken from '@/utils/withCSRFToken';

export default class ReportRelease extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      unknownReleaseOK: props.crashLog && props.crashLog.unknown_release_ok,
    };
  }

  toggleUnknownReleaseOK = (event) => {
    const { crashLog, crashLogPath } = this.props;
    if (crashLog == null) {
      return;
    }

    const unknownReleaseOK = event.currentTarget.checked;

    fetch(`${crashLogPath}.json`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(
        withCSRFToken({
          crash_log: Object.assign({}, crashLog, {
            unknown_release_ok: unknownReleaseOK,
          }),
        }),
      ),
    }).then(() => {
      this.setState({
        unknownReleaseOK,
      });
    });
  };

  render() {
    const {
      crashLog,
      release,
      releasePath,
      submissionRelease,
      submissionReleasePath,
    } = this.props;
    return (
      <div className="report-release">
        {release != null ? (
          <a href={releasePath}>{release.version}</a>
        ) : (
          crashLog != null && (
            <div>
              Release for Crash is unknown. <br />
              Report submitted via{' '}
              <a href={submissionReleasePath}>
                {submissionRelease.version} ({submissionRelease.version_bundle})
              </a>
              <br />
              <div className="unknown-release-toggle-form">
                <input
                  type="checkbox"
                  checked={this.state.unknownReleaseOK}
                  onChange={this.toggleUnknownReleaseOK}
                />
                <label>Unknown release OK?</label>
              </div>
            </div>
          )
        )}
      </div>
    );
  }
}
