import { AlertCircleIcon, CircleCheck } from 'lucide-react';
import React from 'react';
import { ReactNode } from 'react';
import { ExternalToast, toast } from 'sonner';

export const toastSuccess = (
  message: string | ReactNode,
  options?: ExternalToast,
) =>
  toast.success(message, {
    icon: <CircleCheck className="w-5 h-5 text-background fill-success" />,
    ...options,
  });

export const toastError = (
  message: string | ReactNode,
  options?: ExternalToast,
) =>
  toast.error(message, {
    icon: (
      <AlertCircleIcon className="w-5 h-5 text-background fill-destructive" />
    ),
    ...options,
  });
