import React from 'react';
import { Avatar, AvatarFallback, AvatarImage } from './avatar';

export const UserAvatar = ({
  userId,
  name = '',
  className,
}: {
  userId: number;
  name: string;
  className?: string;
}) => {
  return (
    <Avatar title={name} className={className}>
      <AvatarImage
        src={`/settings/avatar_for_user?thumb=32&user_id=${userId}`}
        alt={name}
      />
      <AvatarFallback>{name.slice(0, 2).toUpperCase()}</AvatarFallback>
    </Avatar>
  );
};
